import React from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Player } from '@lottiefiles/react-lottie-player';
import HERO_ANIMATED_BG from '../../../lotties/solutions_page_hero_bg.json';
import HERO_IMAGE from '../../../images/product-assets/Solutions_Hero_image.svg';
import { formatText } from '../../../utils';
import { SOLUTIONS_TEXT_TO_ANIMATE } from '../utilities';
import useIsPageLoaded from '../../../hooks/useIsPageLoaded';
import { useModal } from '../../../context/ModalContext';

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.section`
    padding-top: 152px;
    width: 100%;
    margin: auto;

    @media only screen and (max-width: 850px) {
        padding-top: 136px;
    }

    @media only screen and (max-width: 560px) {
        padding-top: 118px;
    }
`;

const Content = styled.div`
    max-width: 1280px;
    padding: 0 24px;
    margin: auto;
    display: flex;

    @media only screen and (max-width: 850px) {
        padding: 0 20px;
    }

    @media only screen and (max-width: 560px) {
        flex-direction: column;
        align-items: center;
    }
`;

const LeftContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media only screen and (max-width: 560px) {
        align-items: center;
    }
`;

const PageName = styled.div`
    color: #175cd3;
    font-size: 14px;
    font-weight: 400;
    line-height: 14.22px;
    text-transform: uppercase;

    @media only screen and (max-width: 850px) {
        font-size: 12px;
    }
`;

const HeadingContainer = styled.div`
    padding: 28px 0;

    @media only screen and (max-width: 560px) {
        padding: 16px 0;
    }
`;

const SharedTextStyle = css`
    color: #262626;
    font-size: 54px;
    line-height: 64.8px;
    font-weight: 500;

    @media only screen and (max-width: 1024px) {
        font-size: 36px;
        line-height: 43.2px;
        max-width: 100%;
    }

    @media only screen and (max-width: 786px) {
        font-size: 30px;
        line-height: 43.2px;
        max-width: 100%;
    }

    @media only screen and (max-width: 560px) {
        font-size: 24px;
        line-height: 28.44px;
        text-align: center;
    }
`;

const Heading = styled.h1`
    ${SharedTextStyle}
    user-select: none;
    max-width: 598px;

    @media only screen and (max-width: 1024px) {
        max-width: 80%;
    }

    @media only screen and (max-width: 850px) {
        max-width: 100%;
    }

    @media only screen and (max-width: 768px) {
        max-width: 80%;
    }

    @media only screen and (max-width: 560px) {
        max-width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
    }
`;

const AnimatedTextContainer = styled.div`
    display: inline-block;
    overflow: hidden;
    background-color: #2e90fa;
    padding: 10px 12px;
    margin-top: 8px;

    @media only screen and (max-width: 560px) {
        text-align: center;
        padding: 8px;
    }
`;

const AnimatedHeadingText = styled.h1`
    ${SharedTextStyle}
    white-space: nowrap;
    font-style: italic;
    color: #fff;
`;

const SubText = styled.p`
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: #262626;
    max-width: 520px;

    @media only screen and (max-width: 850px) {
        font-size: 16px;
        line-height: 24px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
    }
`;

const CtaContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;
    padding-top: 44px;

    @media only screen and (max-width: 560px) {
        padding: 28px 70.5px 22px 70.5px;
        flex-direction: column;
        width: 100%;
        gap: 14px;
    }
`;

const RightContent = styled.div`
    flex: 1;
    margin: auto;

    @media only screen and (max-width: 850px) {
        flex: 0.9;
    }
`;
const ImageContainer = styled.div`
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media only screen and (max-width: 560px) {
        max-width: 100%;
    }
`;
const Image = styled.img`
    height: 100%;
    width: 89%;
    margin: auto;

    @media only screen and (max-width: 560px) {
        max-width: 90%;
        margin: auto;
    }
`;

const AnimationPlayer = styled(Player)`
    width: 90%;
    height: 100%;
    object-fit: contain;

    @media only screen and (max-width: 560px) {
        max-width: 90%;
        margin: auto;
    }
`;

const SharedStyle = css`
    text-decoration: none;
    outline: none;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #0c1a68;
    transition: all 0.2s linear;

    ${({ varient }) => {
        if (varient === 'filled') {
            return css`
                background-color: #0c1a68;
                color: #fff;
            `;
        } else if (varient === 'outline') {
            return css`
                background-color: transparent;
                color: #0c1a68;
            `;
        }
    }}

    &:hover {
        transform: scale(1.08);
    }
`;

const CtaButton = styled.button`
    ${SharedStyle}
`;

const ExternalLink = styled.a`
    ${SharedStyle}
`;
const HeroSection = () => {
    const { isLoaded } = useIsPageLoaded();
    const { handleOpen } = useModal();

    const [textIndex, setTextIndex] = React.useState(0);
    const textRef = React.useRef(null);
    const containerRef = React.useRef(null);

    React.useEffect(() => {
        const animateText = () => {
            const tl = gsap.timeline();

            tl.to(containerRef.current, {
                width: containerRef?.current?.scrollWidth,
                duration: 0.2,
                ease: 'none',
            })
                .to(textRef.current, {
                    y: -60,
                    duration: 0.1,
                })
                .add(() => updateTextIndex())
                .add(() => animateNewText());
        };

        const updateTextIndex = () => {
            setTextIndex((prevIndex) => (prevIndex + 1) % SOLUTIONS_TEXT_TO_ANIMATE.length);
        };

        const animateNewText = () => {
            requestAnimationFrame(() => {
                gsap.fromTo(
                    textRef.current,
                    { y: 60 },
                    {
                        y: 0,
                        duration: 0.2,
                        ease: 'none',
                    }
                );
                gsap.to(containerRef.current, {
                    width: 'auto',
                    duration: 0.2,
                    ease: 'none',
                });
            });
        };

        const interval = setInterval(animateText, 2500);

        return () => clearInterval(interval);
    }, [textIndex, textRef, containerRef]);

    React.useEffect(() => {
        gsap.fromTo(
            '.left-content',
            { y: 60, opacity: 0 },
            {
                y: 0,
                opacity: 1,
                duration: 0.6,
                ease: 'none',
                scrollTrigger: {
                    trigger: '.left-content',
                    start: 'top bottom',
                },
            }
        );

        gsap.fromTo(
            '.image-content',
            { opacity: 0, scale: 0.95 },
            {
                opacity: 1,
                duration: 0.6,
                scale: 1,
                ease: 'none',
                scrollTrigger: {
                    trigger: '.image-content',
                    start: 'top bottom',
                },
            }
        );
    }, []);

    return (
        <Wrapper>
            <Content>
                <LeftContent className="left-content">
                    <PageName>Solutions • KYC & AML</PageName>
                    <HeadingContainer>
                        <Heading>
                            Streamlined Global KYC & Automated AML Compliance for
                            <AnimatedTextContainer ref={containerRef}>
                                <AnimatedHeadingText ref={textRef}>
                                    {SOLUTIONS_TEXT_TO_ANIMATE[textIndex]}
                                </AnimatedHeadingText>
                            </AnimatedTextContainer>
                        </Heading>
                    </HeadingContainer>
                    <SubText>
                        {formatText(`Simplify Your KYC/AML Compliance with Authenticate®: Verify Users Instantly,
                        Prevent Fraud, and Fulfill All Compliance Needs Effortlessly.`)}
                    </SubText>
                    <CtaContainer>
                        <ExternalLink
                            varient="filled"
                            target="_blank"
                            rel="noreferrer"
                            href="https://portal.authenticate.com/"
                        >
                            Get Started
                        </ExternalLink>
                        <CtaButton onClick={handleOpen} varient="outline" type="button">
                            Schedule a Call
                        </CtaButton>
                    </CtaContainer>
                </LeftContent>
                <RightContent className="image-content">
                    <ImageContainer>
                        {/* <Image src={HERO_IMAGE} alt="hero" /> */}
                        {!isLoaded ? (
                            <Image src={HERO_IMAGE} alt="hero" />
                        ) : (
                            <AnimationPlayer src={HERO_ANIMATED_BG} loop autoplay />
                        )}
                    </ImageContainer>
                </RightContent>
            </Content>
        </Wrapper>
    );
};

export default HeroSection;
