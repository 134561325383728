import React from 'react';
import RootLayout from '../../layouts/RootLayout';
import HeroSection from '../../sections/solutions/kyc-aml-compliance/HeroSection';
import VendorsSection from '../../sections/solutions/kyc-aml-compliance/VendorsSection';
import Features from '../../sections/solutions/kyc-aml-compliance/Features';
import ExpertSolutions from '../../sections/solutions/kyc-aml-compliance/ExpertSolutions';
import TrustAuthenticateSection from '../../sections/solutions/kyc-aml-compliance/TrustAuthenticateSection';
import PrivacyProtections from '../../sections/solutions/kyc-aml-compliance/PrivacyProtections';
import Resources from '../../components/reusable/Resources';
import {
    KYC_AML_COMPLIANCE_PAGE_RESOURCES,
    KYC_AML_FAQ,
    KYC_AML_PAGE_BOTTOM_BANNER_CONTENTS,
} from '../../sections/solutions/utilities';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import BottomBanner from '../../components/reusable/BottomBanner';
const Solutions = () => {
    return (
        <RootLayout>
            <HeroSection />
            <VendorsSection />
            <Features />
            <ExpertSolutions />
            <TrustAuthenticateSection />
            <PrivacyProtections />
            <Resources item={KYC_AML_COMPLIANCE_PAGE_RESOURCES} />
            <ProductFaq
                faqData={KYC_AML_FAQ}
                subHeading="Everything you need to know about KYC/AML Compliance."
                titleColor="#262626"
                animation
            />
            <BottomBanner data={KYC_AML_PAGE_BOTTOM_BANNER_CONTENTS} />
        </RootLayout>
    );
};

export default Solutions;
