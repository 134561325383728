import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'gatsby';

gsap.registerPlugin(ScrollTrigger);

const SolutionCard = styled.div`
    background: #175cd3;
    border-radius: 24px;
    display: flex;
    flex-direction: ${({ position }) => (position === 'reverse' ? 'row-reverse' : '')};
    padding: 52px 36px;

    @media only screen and (max-width: 850px) {
        padding: 32px 24px;
    }

    @media only screen and (max-width: 560px) {
        flex-direction: column-reverse;
        padding: 24px 16px;
        border-radius: 16px;
    }
`;

const LeftContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const HeadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media only screen and (max-width: 560px) {
        padding: 36px 0 32px 0;
    }
`;

const Heading = styled.h1`
    font-size: 32px;
    line-height: 40px;
    color: #ffffff;
    font-weight: 500;

    @media only screen and (max-width: 850px) {
        font-size: 22px;
        line-height: 27.5px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 14px;
        line-height: 17.5px;
    }
`;

const SubText = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;

    @media only screen and (max-width: 850px) {
        font-size: 12px;
        line-height: 18px;
    }
`;

const ProductsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 28px;

    @media only screen and (max-width: 850px) {
        gap: 15px;
    }

    @media only screen and (max-width: 560px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
    }
`;

const IconContainer = styled.div`
    height: 34px;
    min-width: 34px;
    background: ${({ bgColor }) => bgColor};
    border-radius: 5.82px;
    display: grid;
    place-items: center;

    @media only screen and (max-width: 850px) {
        height: 24px;
        min-width: 24px;
    }
`;

const Icon = styled.img`
    height: 20px;
    width: 20px;

    @media only screen and (max-width: 850px) {
        height: 14px;
        width: 14px;
    }
`;

const Text = styled.h4`
    font-size: 16px;
    line-height: 20px;
    color: #1d2939;
    font-weight: 400;

    @media only screen and (max-width: 850px) {
        font-size: 12px;
        line-height: 15px;
    }

    @media only screen and (max-width: 560px) {
        font-weight: 500;
        line-height: 15px;
    }
`;

const Product = styled.div`
    background-color: #fff;
    border-radius: 12px;
    padding: 14px 16px;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    cursor: default;

    &:hover {
        ${Text} {
            font-weight: 500;
        }
    }

    @media only screen and (max-width: 850px) {
        padding: 8px;
        border-radius: 6px;
    }

    @media only screen and (max-width: 560px) {
        border-radius: 6px;
        gap: 8px;
    }
`;

const RightContent = styled.div`
    flex: 1;
`;

const Image = styled.img`
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-in-out;
`;

const ImageContainer = styled.div`
    background-color: #ffffff;
    height: 100%;
    width: 90%;
    border-radius: 24px;
    padding: 48px 30px;
    cursor: pointer;
    ${css`
        margin-${({ position }) => (position === 'reverse' ? 'right' : 'left')}: auto;
    `}
    &:hover {
        ${Image} {
            transform: scale(1.1);
        }
    }

    @media only screen and (max-width: 560px) {
        width: 100%;
        height: 245px;
    }
`;

const SolutionsCard = ({ data, position = 'default', link }) => {
    const [hoverItem, setHoverItem] = useState(null);
    const imageRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const animationTargets = [containerRef.current, imageRef.current];

        const animations = animationTargets.map((target) =>
            gsap.fromTo(
                target,
                { scale: 0.9, opacity: 0 },
                {
                    delay: 0.2,
                    scale: 1,
                    opacity: 1,
                    duration: 0.6,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: target,
                        toggleActions: 'play none none none',
                    },
                }
            )
        );

        return () => {
            animations.forEach((animation) => {
                animation.kill();
                if (animation.scrollTrigger) {
                    animation.scrollTrigger.kill();
                }
            });
        };
    }, []);

    return (
        <SolutionCard position={position} ref={containerRef}>
            <LeftContent>
                <HeadingWrapper>
                    <Heading>{data?.heading}</Heading>
                    <SubText>{data?.subtext}</SubText>
                </HeadingWrapper>
                <ProductsContainer>
                    {data?.products?.map((prod) => (
                        <Product
                            key={prod.id}
                            onMouseEnter={() => setHoverItem(prod.id)}
                            onMouseLeave={() => setHoverItem(null)}
                        >
                            <IconContainer bgColor={prod.bgColor}>
                                <Icon src={hoverItem === prod.id ? prod.hoverIcon : prod.icon} />
                            </IconContainer>
                            <Text>{prod.name}</Text>
                        </Product>
                    ))}
                </ProductsContainer>
            </LeftContent>
            <RightContent>
                <ImageContainer position={position} ref={imageRef}>
                    <Link to={link}>
                        <Image src={data.heroImage} alt={data.heading} />
                    </Link>
                </ImageContainer>
            </RightContent>
        </SolutionCard>
    );
};

SolutionsCard.propTypes = {
    data: PropTypes.object,
    position: PropTypes.oneOf(['default', 'reverse']),
    link: PropTypes.string,
};

export default SolutionsCard;
