import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import BACKGROUND from '../../../images/solutions-gradient-bg.png';
import ArrowRight from '../../../images/product-assets/arrow-right-dark.svg';

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div`
    background: url(${BACKGROUND}) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    border: 1px solid #eaecf0;
    overflow: hidden;
    padding: 36px;
    display: flex;
    margin-top: 54px;
    transform-origin: center;
    transition: transform 0.1s ease-out;

    @media only screen and (max-width: 850px) {
        margin-top: 30px;
        padding: 24px;
    }

    @media only screen and (max-width: 560px) {
        flex-direction: column;
        padding: 16px;
        border-radius: 16px;
        margin-top: 22px;
    }
`;

const LeftContent = styled.div`
    flex: 1;
`;

const Heading = styled.h1`
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    color: #fff;
    max-width: 434px;

    @media only screen and (max-width: 850px) {
        font-size: 24px;
        line-height: 27.5px;
        max-width: 270px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 16px;
        line-height: 20px;
    }
`;

const RightContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 36px;

    @media only screen and (max-width: 850px) {
        gap: 24px;
    }
`;

const Para = styled.p`
    font-size: 18px;
    line-height: 27px;
    color: #fff;

    @media only screen and (max-width: 850px) {
        font-size: 14px;
        line-height: 21px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 12px;
        line-height: 16px;
        padding-top: 12px;
    }
`;

const CtaContainer = styled.div`
    display: flex;
    gap: 16px;
`;

const SharedStyle = css`
    font-family: 'Rubik', sans-serif;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 8px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s linear;

    > span {
        white-space: nowrap;
    }

    @media only screen and (max-width: 850px) {
        font-size: 12px;
    }

    @media only screen and (max-width: 560px) {
        padding: 6px 14px;
    }

    &:hover {
        transform: scale(1.08);
    }
`;

const ExternalLink = styled.a`
    ${SharedStyle}

    background: #fff;
    color: #000;
`;

const CtaButton = styled.button`
    ${SharedStyle}
    background: transparent;
    color: #fff;
`;
const Banner = ({ handleOpen }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const banner = containerRef.current;
            const rect = banner.getBoundingClientRect(); //: This method returns a DOMRect object with properties that describe the size and position of the element.
            const windowHeight = window.innerHeight;

            const startFade = windowHeight;
            const endFade = 0;

            const progress = Math.min(Math.max((rect.top - endFade) / (startFade - endFade), 0), 1);

            const scaleValue = 1 + progress * 0.06;
            banner.style.transform = `scale(${scaleValue})`;
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Container ref={containerRef}>
            <LeftContent>
                <Heading>
                    Instant User Verification, Fraud Prevention, and Compliance—All in One!
                </Heading>
            </LeftContent>
            <RightContent>
                <Para>
                    Experience seamless KYC and AML compliance. Verify users in just 30 seconds and
                    save up to 73% on verification costs!
                </Para>
                <CtaContainer>
                    <ExternalLink
                        href="https://portal.authenticate.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span>Get Started</span>
                        <img src={ArrowRight} alt="arrow-right" />
                    </ExternalLink>
                    <CtaButton type="button" onClick={handleOpen}>
                        <span>Schedule a Demo</span>
                    </CtaButton>
                </CtaContainer>
            </RightContent>
        </Container>
    );
};

Banner.propTypes = {
    handleOpen: PropTypes.func.isRequired,
};

export default Banner;
