import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { formatText } from '../../../utils';
import { vendorLogosList } from '../../../helper/constant';

const Wrapper = styled.section`
    padding-top: 78px;

    @media only screen and (max-width: 850px) {
        padding-top: 64px;
    }
    @media only screen and (max-width: 560px) {
        padding-top: 36px;
    }
`;

const Content = styled.div`
    overflow: hidden;
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;

    @media only screen and (max-width: 850px) {
        gap: 24px;
    }
`;

const Heading = styled.h3`
    color: #262626;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;

    @media screen and (max-width: 850px) {
        font-size: 14px;
        line-height: 14.22px;
    }
`;

const SharedStyle = css`
    content: '';
    position: absolute;
    height: 100%;
    width: 230px;
    top: 0;
    bottom: 0;
    z-index: 1;

    @media screen and (max-width: 600px) {
        width: 150px;
    }
`;

const MarqueeWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    gap: 60px;
    overflow: hidden;

    &::before {
        ${SharedStyle}
        background: linear-gradient(to right, #fff 10%, transparent 90%);
        left: 0;
    }

    &::after {
        ${SharedStyle}
        background: linear-gradient(90deg, transparent 10%, #fff 90%);
        right: 0;
    }
`;

const ScrollX = keyframes`
    from{
        left: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
`;

const MarqueeGroup = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 60px;
    animation: ${ScrollX} 20s linear infinite;

    @media only screen and (max-width: 600px) {
        gap: 28px;
    }
`;

const CompanyLogo = styled.img`
    height: ${({ customSize }) => (customSize ? '14px' : '20px')};
    width: auto;

    @media screen and (max-width: 500px) {
        height: auto;
        max-height: 28px;
    }
`;

const VendorsSection = () => {
    return (
        <Wrapper>
            <Content>
                <Heading>{formatText('Authenticate® is trusted by')}</Heading>
                <MarqueeWrapper>
                    {Array.from({ length: 2 }).map((_, index) => (
                        <MarqueeGroup key={index + 1}>
                            {vendorLogosList.map((data) => (
                                <CompanyLogo
                                    src={data.logo}
                                    alt={data.name}
                                    customSize={data?.customSize}
                                    key={data.id}
                                />
                            ))}
                        </MarqueeGroup>
                    ))}
                </MarqueeWrapper>
            </Content>
        </Wrapper>
    );
};

export default VendorsSection;
