import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { formatText } from '../../utils';

import Map from '../../images/product-assets/World-map.svg';
import Locations from '../../images/product-assets/world-map-locations.svg';
import ArrowRight from '../../images/product-assets/chevron-right.svg';
import MOBILE_GRADIENT from '../../images/product-assets/footer-banner-gradient-image.svg';

gsap.registerPlugin(ScrollTrigger);

const Container = styled.section`
    margin-top: 110px;
    overflow: hidden;
    background-image: linear-gradient(
        90deg,
        hsl(218deg 85% 26%) 0%,
        hsl(216deg 84% 28%) 10%,
        hsl(215deg 84% 30%) 20%,
        hsl(213deg 84% 31%) 30%,
        hsl(211deg 82% 36%) 40%,
        hsl(209deg 81% 42%) 50%,
        hsl(206deg 82% 47%) 60%,
        hsl(205deg 83% 49%) 70%,
        hsl(207deg 82% 45%) 80%,
        hsl(208deg 82% 41%) 90%,
        hsl(210deg 84% 36%) 100%
    );
    @media only screen and (max-width: 850px) {
        margin-top: 72px;
    }

    @media only screen and (max-width: 560px) {
        margin-top: 54px;
        background: #0b449d url(${MOBILE_GRADIENT}) center center/cover;
    }
`;

const ScheduleBox = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 54px 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 44px;

    @media screen and (max-width: 850px) {
        padding: 14px 28px;
    }
    @media screen and (max-width: 580px) {
        flex-direction: column-reverse;
        justify-content: center;
        padding: 32px 16px;
    }
`;

const TextContainer = styled.div`
    width: 40%;
    @media screen and (max-width: 580px) {
        text-align: center;
        width: 100%;
    }
`;

const Heading = styled.h1`
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    color: #fff;
`;

const SubHeading = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    margin-top: 8px;
    max-width: 384px;

    @media screen and (max-width: 960px) {
        font-size: 14px;
        line-height: 17px;
    }

    @media screen and (max-width: 580px) {
        line-height: 20px;
        margin-top: 16px;
        padding: 0 16px;
    }
`;

const Cta = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background-color: #fff;
    width: max-content;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 32px;
    text-decoration: none;

    &:hover {
        img {
            transition: all 0.3s ease-in-out;
            transform: translateX(5px);
        }
    }

    span {
        font-family: 'Rubik', sans-serif;
        font-weight: 500;
        color: #333333;
    }

    @media screen and (max-width: 960px) {
        margin-top: 32px;
    }

    @media screen and (max-width: 560px) {
        margin: 32px auto 0 auto;
    }
`;

const ImageContainer = styled.div`
    width: 60%;
    position: relative;
    @media screen and (max-width: 580px) {
        width: 100%;
    }
`;

const Image = styled.img`
    height: 300px;
    width: 100%;
    position: relative;

    @media screen and (max-width: 580px) {
        height: auto;
        width: 100%;
    }
`;

const LocationImage = styled.img`
    position: absolute;
    height: 300px;
    width: 100%;
    top: -10px;
    left: 36px;
    z-index: 1;

    @media only screen and (max-width: 850px) {
        top: -5px;
        left: 24px;
    }

    @media only screen and (max-width: 580px) {
        height: 100%;
        width: 100%;
        top: -8px;
        left: 23px;
    }
`;

const BottomBanner = ({ data }) => {
    useEffect(() => {
        gsap.fromTo(
            '.text-container',
            { y: 30, opacity: 0 },
            {
                y: 0,
                opacity: 1,
                duration: 0.3,
                ease: 'none',
                scrollTrigger: {
                    trigger: '.text-container',
                    start: 'top bottom',
                },
            }
        );
        gsap.fromTo(
            '.cta',
            { y: 20, opacity: 0 },
            {
                y: 0,
                delay: 0.1,
                opacity: 1,
                duration: 0.5,
                ease: 'none',
                scrollTrigger: {
                    trigger: '.cta',
                    start: 'top bottom',
                },
            }
        );
        gsap.fromTo(
            '.locations',
            { opacity: 0 },
            {
                opacity: 1,
                delay: 0.4,
                duration: 0.8,
                ease: 'none',
                scrollTrigger: {
                    trigger: '.locations',
                },
            }
        );
    }, []);
    return (
        <Container id="schedule">
            <ScheduleBox>
                <TextContainer>
                    <Heading className="text-container">{data.title}</Heading>
                    <SubHeading className="text-container">{formatText(data.subtext)}</SubHeading>
                    <Cta
                        className="cta"
                        target="_blank"
                        rel="noreferrer"
                        href="https://portal.authenticate.com"
                    >
                        <span>{data.buttonText}</span>
                        <img src={ArrowRight} alt="Arrow right" />
                    </Cta>
                </TextContainer>
                <ImageContainer>
                    <LocationImage src={Locations} alt="Location" className="locations" />
                    <Image src={Map} alt="World map" width="auto" height="auto" />
                </ImageContainer>
            </ScheduleBox>
        </Container>
    );
};

BottomBanner.propTypes = {
    data: PropTypes.object.isRequired,
};

export default BottomBanner;
