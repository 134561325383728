import React, { useEffect } from 'react';
import styled from 'styled-components';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SolutionsCard from '../components/SolutionsCard';
import { BACKGROUND_CHECKS, KYC_SOLUTIONS } from '../utilities';
import Banner from '../components/Banner';
import { useModal } from '../../../context/ModalContext';

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.section`
    max-width: 1280px;
    margin: auto;
    padding-top: 110px;

    @media only screen and (max-width: 850px) {
        padding-top: 72px;
    }

    @media only screen and (max-width: 560px) {
        padding-top: 54px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 56px;
    padding: 0 24px;

    @media only screen and (max-width: 850px) {
        gap: 44px;
    }

    @media only screen and (max-width: 560px) {
        gap: 32px;
        padding: 0 12px;
    }
`;

const Heading = styled.h1`
    font-size: 42px;
    font-weight: 500;
    line-height: 50.4px;
    color: #262626;
    max-width: 799px;
    text-align: center;
    margin: auto;

    @media only screen and (max-width: 850px) {
        font-size: 32px;
        line-height: 31.2px;
        max-width: 600px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 24px;
        line-height: 28.8px;
        max-width: 80%;
    }
`;

const ExpertSolutions = () => {
    const { handleOpen } = useModal();
    useEffect(() => {
        gsap.fromTo(
            '.heading-text',
            { y: 100, opacity: 0 },
            {
                y: 0,
                opacity: 1,
                duration: 0.5,
                ease: 'none',
                scrollTrigger: {
                    trigger: '.heading-text',
                    toggleActions: 'play none none none',
                },
            }
        );
    }, []);

    return (
        <Wrapper>
            <Content>
                <Heading className="heading-text">
                    Secure and Simplify Compliance with Expert Solutions
                </Heading>
                <SolutionsCard data={KYC_SOLUTIONS} link="/products/identity" />
                <SolutionsCard
                    data={BACKGROUND_CHECKS}
                    position="reverse"
                    link="/products/criminal-background-checks"
                />
                <Banner handleOpen={handleOpen} />
            </Content>
        </Wrapper>
    );
};

export default ExpertSolutions;
