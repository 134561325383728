import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { KYC_AML_FEATURE_HIGHLIGHTS } from '../utilities';
import Checkmark from '../../../images/product-assets/check-mark-filled.svg';

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.section`
    max-width: 1280px;
    margin: auto;
    padding-top: 116px;

    @media only screen and (max-width: 850px) {
        padding-top: 94px;
    }

    @media only screen and (max-width: 560px) {
        padding-top: 64px;
    }
`;

const Content = styled.div`
    padding: 0 26px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;

    @media only screen and (max-width: 560px) {
        padding: 0 16px;
        flex-direction: column;
    }
`;

const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
    transition: all 0.5s ease-in-out;
    transform-origin: center;

    @media only screen and (max-width: 600px) {
        width: 75%;
        transition: none;
    }
`;

const Card = styled.div`
    flex: 1;
    border: 1px solid #eaecf0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: #fff;

    &:hover ${Image} {
        transform: scale(1.1);
    }

    @media only screen and (max-width: 600px) {
        &:hover ${Image} {
            transform: none;
        }
    }
`;

const ImageContainer = styled.div`
    background: #f8f9fc;
    padding: 20px 28px;
    border-radius: 12px;
    margin: 24px 20px;
    text-align: center;

    @media only screen and (max-width: 850px) {
        margin: 16px 12px;
    }
`;

const Description = styled.div`
    margin: 0 20px 16px 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media only screen and (max-width: 850px) {
        margin: 0 12px 16px 12px;
        gap: 8px;
    }
    @media only screen and (max-width: 600px) {
        gap: 4px;
    }
`;

const Heading = styled.h3`
    font-size: 24px;
    font-weight: 500;
    line-height: 31.2px;
    color: #1d2939;
    text-align: left;

    @media only screen and (max-width: 850px) {
        font-size: 14px;
        line-height: 18.2px;
    }
`;

const SubText = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 16.22px;
    color: #1d2939;

    @media only screen and (max-width: 850px) {
        font-size: 12px;
        line-height: 16.8px;
    }
`;

const Ul = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media only screen and (max-width: 850px) {
        gap: 8px;
    }
    @media only screen and (max-width: 600px) {
        gap: 4px;
    }
`;

const Li = styled.li`
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: #475467;
    list-style: none;
    padding-left: 30px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16.25px;
        height: 16.25px;
        background-color: #1849a9;
        -webkit-mask-image: url(${Checkmark});
        mask-image: url(${Checkmark});
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    @media only screen and (max-width: 850px) {
        font-size: 12px;
        line-height: 16.8px;
        padding-left: 25px;

        &::before {
            width: 14px;
            height: 14px;
            top: 8px;
        }
    }
`;

const Features = () => {
    const cardRefs = useRef([]);

    useEffect(() => {
        const cards = cardRefs?.current;

        cards.forEach((card, index) => {
            gsap.fromTo(
                card,
                {
                    opacity: 0,
                    scale: 0.9,
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 0.6,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: card,
                        start: 'top 90%',
                        toggleActions: 'play none none none',
                    },
                }
            );
        });

        return () => {
            cards?.forEach((card) => {
                gsap.killTweensOf(card);
                if (card?.scrollTrigger) {
                    card.scrollTrigger.kill();
                }
            });
        };
    }, []);

    return (
        <Wrapper>
            <Content>
                {KYC_AML_FEATURE_HIGHLIGHTS.map((feat, index) => (
                    <Card key={feat.id} ref={(el) => (cardRefs.current[index] = el)}>
                        <ImageContainer>
                            <Image src={feat.img} alt={feat.title} />
                        </ImageContainer>
                        <Description>
                            <Heading>{feat.title}</Heading>
                            <SubText>{feat.subtext}</SubText>
                            <Ul>
                                {feat.lists.map((list) => (
                                    <Li key={list}>{list}</Li>
                                ))}
                            </Ul>
                        </Description>
                    </Card>
                ))}
            </Content>
        </Wrapper>
    );
};

export default Features;
